Ember.TEMPLATES["javascripts/discourse/connectors/composer-fields/viewlevel-selection"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,0],[12],[1,\"\\n\"],[1,[28,[35,0],null,[[\"value\",\"content\",\"class\",\"onChange\"],[[33,1,[\"viewlevel\"]],[33,1,[\"viewlevelOptions\"]],\"search-logs-filter\",[28,[37,2],[[30,0],[28,[37,3],[[33,1,[\"viewlevel\"]]],null]],null]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[],false,[\"combo-box\",\"model\",\"action\",\"mut\"]]","moduleName":"javascripts/discourse/connectors/composer-fields/viewlevel-selection","isStrictMode":false});
define("discourse/plugins/discourse-post-viewlevel/discourse/initializers/extend-composer", ["exports", "@ember/object", "discourse/models/composer", "discourse/models/post", "discourse/lib/ajax"], function (_exports, _object, _composer, _post, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "extend-composer",

    initialize() {
      _composer.default.reopen({
        viewlevelSaved: 0,
        viewlevel: 0,

        init() {
          this._super(...arguments);

          this.viewlevelOptions = [{
            id: 0,
            name: "所有人可见"
          }, {
            id: 501,
            name: "从七品可见"
          }, {
            id: 1001,
            name: "正七品可见"
          }, {
            id: 2001,
            name: "从六品可见"
          }, {
            id: 4001,
            name: "正六品可见"
          }, {
            id: 8001,
            name: "从五品可见"
          }, {
            id: 16001,
            name: "正五品可见"
          }, {
            id: 32001,
            name: "从四品可见"
          }, {
            id: 64001,
            name: "正四品可见"
          }, {
            id: 128001,
            name: "从三品可见"
          }, {
            id: 256001,
            name: "正三品可见"
          }, {
            id: 512001,
            name: "从二品可见"
          }, {
            id: 1024001,
            name: "正二品可见"
          }, {
            id: 2024001,
            name: "从一品可见"
          }, {
            id: 3024001,
            name: "正一品可见"
          }, {
            id: 4024001,
            name: "超一品可见"
          }];
        },

        open(opts) {
          const composer = this;
          return this._super(opts).then(result => {
            composer.viewlevelSaved = composer.viewlevel = composer.action == "edit" && composer.post && composer.post.viewlevel ? composer.post.viewlevel : 0;
            return result;
          });
        },

        save(opts) {
          const composer = this;
          const post = composer.post;
          var isEditing = this.editingPost;
          return this._super(opts).then(result => {
            if (composer.composerOpened) return result;
            if (isEditing && composer.viewlevelSaved == composer.viewlevel) return result;
            var postid = isEditing ? post.id : composer.createdPost.id;
            (0, _ajax.ajax)("/post-view-control", {
              type: "POST",
              data: {
                id: postid,
                viewlevel: composer.viewlevel,
                create: !isEditing
              }
            });
            return result;
          });
        }

      });
    }

  };
  _exports.default = _default;
});

